import MyMapGoogle from "./map";
import React from "react";

export function Bandeau() {
    return (<div className={"bg-[#022855] h-[10vh]"} />);
}

export function Accueil() {
    return (
        <div className={"h-screen flex justify-center items-center align-middle"}>
            <div className={"md:lg:max-h-[300px] lg:max-h-[300px] bg-white flex items-end bg-opacity-75 max-w-[90vw] sm:max-w-[75vw] md:max-w-[60vw] lg:max-w-[55vw] justify-center"}>
                <div className={"h-full max-w-full flex flex-col items-center justify-end"}>
                    <div className={"flex justify-center items-center"}>
                        <img src={"../logo/Imwest.png"} alt={""} className={"w-[70%] sm:w-[60%] md:w-[50%] lg:w-[40%]"}/>
                    </div>
                    <div className={"m-4 text-center"}>
                        <span className={"text-sm"}>
                            {"Basé à Lorient, le Groupe IMWEST est une référence de l’immobilier neuf haut de gamme en Bretagne Sud. Composé des sociétés Horizon Grand Large, West Construction et Côte et Mer Immobilier, le Groupe IMWEST vous accompagne depuis plus de quinze ans pour vivre sereinement chaque étape de votre projet immobilier."}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Societes() {
    return (
        <div className={"h-full"}>
            <Bandeau />
            <div className={"bg-[#022855]"}>
                <div className={"flex flex-wrap justify-center items-stretch h-full"}>
                    <ComposantSociete
                        title={"Horizon Grand Large"}
                        content={"Pôle promotion immobilière du Groupe. Avec plus d’une vingtaine de " +
                            "résidences de standing réalisées en quinze ans, c’est avec expérience et savoir-faire que nous vous " +
                            "proposons des logements haut de gamme, idéalement conçus et aux prestations de qualité."}
                        logo={"../logo/HGL.png"}
                        site={"https://www.horizongrandlarge.fr"}
                    />
                    <ComposantSociete
                        title={"West Construction"}
                        content={"Spécialisée en aménagements intérieurs. Elle a été créée pour répondre aux " +
                            "demandes de nos acquéreurs les plus exigeants. Nos compagnons très qualifiés sont formés pour " +
                            "réaliser des finitions de qualité et des aménagements sur-mesure."}
                        logo={"../logo/WestConst.png"}
                    />
                    <ComposantSociete
                        title={"Côte et Mer Immobilier"}
                        content={"Agence immobilière du groupe, spécialisée dans le neuf. Nos " +
                            "négociateurs sont à votre écoute pour vous accompagner tout au long de votre projet, aussi bien sur " +
                            "les questions techniques, administratives et juridiques liées à votre investissement immobilier."}
                        logo={"../logo/CoteetMer.png"}
                    />
                </div>
            </div>
            <Bandeau />
        </div>
    );
}

export function ComposantSociete(
    {
        title, content, logo, site
    }:{
        title: string, content: string, logo: string, site?: string
    }
){
    return (
            <div className={"max-w-[400px] text-center bg-white m-10 p-2 flex flex-col items-center align-top"}>
                <div className={"m-4"}>
                    <img src={logo} alt={""} width={200} height={200}/>
                </div>
                <div className={"m-4"}>
                    <h1 className={"text-xl font-primary"}>{title}</h1>
                </div>
                <div className={"m-4"}>
                    <span className={"text-xs"}>{content}</span>
                </div>
                {site && (
                    <div>
                        <a href={site} target={"_blank"} rel="noreferrer">
                            <button className={"bg-[#022855] px-4 py-2 text-white rounded-md hover:bg-[#283b57]/90"}>En savoir plus</button>
                        </a>
                    </div>
                )}
            </div>
    );
}

export function Contact() {
    return (
        <div className={"h-screen flex flex-col items-center justify-between"}>
            <div
                className={"bg-[#022855] h-full w-full bg-opacity-85 align-middle p-5 justify-center items-center"}>
                <div className={"max-w-full flex flex-col justify-center items-center text-white"}>
                    <div className={"m-4 text-center"}>
                        <h2 className={"text-4xl font-primary"}>Nous contacter</h2>
                    </div>
                    <div className={"m-4 flex justify-evenly flex-wrap w-[80vw]"}>
                        <div className={"flex flex-col p-4 items-center"}>
                            <h3 className={"font-primary text-lg"}>Adresse des bureaux</h3>
                            <span className={"text-sm"}>5 Rue Paul Bert</span>
                            <span className={"text-sm"}>56100 LORIENT</span>
                        </div>
                        <div className={"flex flex-col p-4 items-center"}>
                            <h3 className={"font-primary text-lg"}>Contact</h3>
                            <span className={"text-sm"}>02 97 11 92 69</span>
                            <span className={"text-sm"}>contact@groupe-imwest.com</span>
                        </div>
                        <div className={"flex flex-col p-4 items-center"}>
                            <h3 className={"font-primary text-lg"}>Horaires</h3>
                            <span className={"text-sm"}>Du Lundi au Vendredi</span>
                            <span className={"text-sm"}>9h - 12h / 14h - 17h</span>
                        </div>
                    </div>
                    <div className={"w-[80vw] flex justify-center items-center align-middle"}>
                        <MyMapGoogle />
                    </div>
                </div>
            </div>
            <div className={"bg-[#022855] p-4 w-full flex justify-center items-center"}>
                <span className={"text-white text-sm"}>© Groupe IMWEST</span>
            </div>
        </div>
    );
}
